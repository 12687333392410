import React from "react";
/**
 * @file ValidationError.tsx
 * Component to display react-hook-form validation errors.
 */

type ValidationErrorProps = {
  message: unknown;
  messageDefault: string;
};

function ValidationError({ message, messageDefault }: ValidationErrorProps) {
  if (typeof message === "string" && message.length > 0)
    return <span className="invalid-feedback">{message}</span>;
  return <span className="invalid-feedback">{messageDefault}</span>;
}

export default ValidationError;
export type { ValidationErrorProps };
