import React from "react";
import Form from "react-bootstrap/Form";

import { Spinner } from "../../utils/spinner";

import BackendDown from "./BackendDown";
import OrderSummary, { ContactSummary } from "./OrderSummary";
import {
  useCartStateDispatch,
  setCheckout,
  setShowingAdditionalPaymentOptions
} from "./cartSlice";
import type { CartCheckoutPrepareProps, CartCheckoutProps } from "./cart-types";
import {
  PaymentBraintreeGateway,
  PaymentBraintreeTestGateway,
  PaymentDummyGateway,
  PaymentPromoCodeGateway
} from "./gateways";
import {
  useCheckoutPrepareMutation,
  useCheckoutSubmitMutation
} from "./piosolverStoreApi";
import type { KnownGateway } from "./piosolverStoreApi";
import { Button } from "react-bootstrap";

const DEFAULT_GATEWAY = "braintree";

function isAtReviewStep(state: CartCheckoutProps["state"]) {
  return state.status.step === 3;
}

type SubmitCheckoutFn = ReturnType<typeof useCheckoutSubmitMutation>[0];

interface PaymentProps extends CartCheckoutProps {
  submitCheckout: SubmitCheckoutFn;
}

function cartAvailableGateways(
  cart: CartCheckoutPrepareProps["cart"],
  state: CartCheckoutProps["state"],
  all = false
) {
  if (all || state.isShowingAdditionalPaymentOptions)
    return cart.availableGateways;
  const checkoutGateway = state.checkout?.gateway;
  if (checkoutGateway != null) return [checkoutGateway];
  const defaultGateway = cart.availableGateways.find((g) =>
    g.startsWith(DEFAULT_GATEWAY)
  );
  if (defaultGateway != null) return [defaultGateway];
  return [cart.availableGateways[0]];
}

type AvailablePaymentMethodKey =
  keyof CartCheckoutProps["availablePaymentMethods"];

function cartHasMultiplePaymentOptions(
  cart: CartCheckoutPrepareProps["cart"],
  state: CartCheckoutProps["state"]
) {
  if (cartAvailableGateways(cart, state, true).length > 1) return true;
  const availablePaymentMethods = cart.availablePaymentMethods;
  if (availablePaymentMethods == null) return false;
  // count how many payment methods are enabled
  const enabledCount = Object.keys(availablePaymentMethods).reduce(
    (acc, k) =>
      availablePaymentMethods[k as AvailablePaymentMethodKey]?.enabled
        ? acc + 1
        : acc,
    0
  );
  return enabledCount > 0;
}

function nameForGateway(gateway: KnownGateway) {
  switch (gateway) {
    case "braintree":
      return "Credit Card";
    case "braintree-test":
      return "Credit Card (Test)";
    case "dummy":
      return "Dummy";
    case "promo-code":
      return "Purchase Code";
  }
}

function PaymentForm({
  cart,
  checkout,
  state,
  submitCheckout
}: Pick<SelectGatewayProps, "cart"> &
  Pick<PaymentProps, "checkout"> &
  Pick<PaymentProps, "state" | "submitCheckout">) {
  const checkoutGateway = checkout.gateway;
  const availablePaymentMethods = state.isShowingAdditionalPaymentOptions
    ? cart.availablePaymentMethods
    : {};
  switch (checkoutGateway) {
    case "braintree":
      return (
        <PaymentBraintreeGateway
          availablePaymentMethods={availablePaymentMethods}
          checkout={checkout}
          state={state}
          submitCheckout={submitCheckout}
        />
      );
    case "braintree-test":
      return (
        <PaymentBraintreeTestGateway
          availablePaymentMethods={availablePaymentMethods}
          checkout={checkout}
          state={state}
          submitCheckout={submitCheckout}
        />
      );
    case "dummy":
      return (
        <PaymentDummyGateway
          availablePaymentMethods={availablePaymentMethods}
          checkout={checkout}
          state={state}
          submitCheckout={submitCheckout}
        />
      );
    case "promo-code":
      return (
        <PaymentPromoCodeGateway
          availablePaymentMethods={availablePaymentMethods}
          checkout={checkout}
          state={state}
          submitCheckout={submitCheckout}
        />
      );
  }
  return null;
}

function PaymentDetails({
  cart,
  checkout,
  state,
  selectedGateway,
  submitCheckout
}: Pick<SelectGatewayProps, "cart"> &
  Partial<Pick<PaymentProps, "checkout">> &
  Pick<PaymentProps, "state" | "submitCheckout"> &
  Pick<SelectGatewayProps, "selectedGateway">) {
  if (checkout == null) return null;
  const checkoutGateway = checkout.gateway;
  if (selectedGateway == null) return null;
  if (checkoutGateway == null) return null;
  if (checkoutGateway !== selectedGateway) return <Spinner />;
  return (
    <PaymentForm
      cart={cart}
      checkout={checkout}
      state={state}
      submitCheckout={submitCheckout}
    />
  );
}

function Processing() {
  return (
    <div>
      <h1>Processing</h1>
      <Spinner />
    </div>
  );
}

interface SelectGatewayProps extends CartCheckoutPrepareProps {
  selectedGateway?: KnownGateway;
  setSelectedGateway: (arg?: KnownGateway) => void;
}

function SelectGateway({
  cart,
  selectedGateway,
  setSelectedGateway,
  state
}: SelectGatewayProps) {
  if (state.checkout === null) return null;
  if (cartAvailableGateways(cart, state).length < 2) {
    return null;
  }
  return (
    <Form>
      <h3>Payment Method</h3>
      <div className="mb-3">
        {cartAvailableGateways(cart, state).map((g) => {
          return (
            <Form.Check
              type="radio"
              checked={selectedGateway === g}
              key={g}
              id={`gateway-${g}`}
              label={nameForGateway(g)}
              onChange={(e) => setSelectedGateway(g)}
            />
          );
        })}
      </div>
    </Form>
  );
}

function EnablePaymentOptionChoice({ cart, state }: CartCheckoutPrepareProps) {
  const dispatch = useCartStateDispatch();

  if (state.checkout === null) return null;
  if (state.isShowingAdditionalPaymentOptions) return null;
  if (!cartHasMultiplePaymentOptions(cart, state)) {
    return null;
  }
  return (
    <div className="mt-4 text-center">
      <Button
        variant="link"
        onClick={() => {
          dispatch(setShowingAdditionalPaymentOptions(true));
        }}>
        See additional payment options
      </Button>
    </div>
  );
}

function ReviewHeader({
  cart,
  selectedGateway,
  state
}: CartCheckoutPrepareProps & Pick<SelectGatewayProps, "selectedGateway">) {
  let reviewText = "Review";
  if (selectedGateway === "dummy") reviewText = "Place dummy order";
  else if (selectedGateway === "braintree-test")
    reviewText = "Place test order";
  return (
    <>
      <h1>{reviewText}</h1>
      <ContactSummary cart={cart} state={state} />
      <OrderSummary cart={cart} state={state} />
    </>
  );
}

function PaymentPanel({
  cart,
  checkout,
  state
}: CartCheckoutPrepareProps & Partial<Pick<PaymentProps, "checkout">>) {
  const availableGateways = cartAvailableGateways(cart, state);
  const preselectedGateway =
    availableGateways.length === 1 ? availableGateways[0] : undefined;
  const [selectedGateway, setSelectedGateway] = React.useState<
    KnownGateway | undefined
  >(preselectedGateway);
  const [
    submitCheckout,
    { error: _submitCheckoutError, isLoading: _submitCheckoutLoading }
  ] = useCheckoutSubmitMutation();

  const [
    prepareCheckout,
    {
      data: _prepareCheckoutData,
      error: _prepareCheckoutError,
      isLoading: prepareCheckoutLoading
    }
  ] = useCheckoutPrepareMutation();

  const dispatch = useCartStateDispatch();
  const stateGateway = state.checkout?.gateway;
  const cartId = cart.cartId;

  React.useEffect(() => {
    if (selectedGateway == null) return;
    if (stateGateway === selectedGateway) return;
    if (prepareCheckoutLoading) return;
    prepareCheckout({ cartId, gateway: selectedGateway })
      .then((result) => {
        if ("data" in result) {
          dispatch(setCheckout({ checkout: result.data }));
          setSelectedGateway(result.data.gateway);
        }
        if ("error" in result) dispatch(setCheckout({ checkout: null }));
      })
      .catch((_error) => {
        dispatch(setCheckout({ checkout: null }));
      });
  }, [
    cartId,
    dispatch,
    prepareCheckout,
    prepareCheckoutLoading,
    selectedGateway,
    setSelectedGateway,
    stateGateway
  ]);

  const isReview = isAtReviewStep(state);

  return (
    <div>
      {isReview && (
        <ReviewHeader
          cart={cart}
          selectedGateway={selectedGateway}
          state={state}
        />
      )}
      {!isReview && (
        <SelectGateway
          cart={cart}
          selectedGateway={selectedGateway}
          setSelectedGateway={setSelectedGateway}
          state={state}
        />
      )}
      {prepareCheckoutLoading ? (
        <Spinner />
      ) : (
        <PaymentDetails
          cart={cart}
          checkout={checkout}
          selectedGateway={selectedGateway}
          state={state}
          submitCheckout={submitCheckout}
        />
      )}
      {!isReview &&
        !prepareCheckoutLoading &&
        checkout?.gateway === selectedGateway && (
          <EnablePaymentOptionChoice cart={cart} state={state} />
        )}
    </div>
  );
}

function Payment({ cart, state }: CartCheckoutPrepareProps) {
  // Null means we tried to prepare the transaction and failed
  if (state.checkout === null) return <BackendDown />;

  if (state.status.step > 3) return <Processing />;
  return <PaymentPanel cart={cart} state={state} checkout={state.checkout} />;
}

export default Payment;
