import braintreeDropin from "braintree-web-drop-in";
import type { Dropin, Options } from "braintree-web-drop-in";
import React from "react";

import { Spinner } from "../../../utils/spinner";

import {
  cartToTotalPrice,
  setStatusFailed,
  setNonceAndDeviceData,
  useCartStateDispatch,
  setRequestingNonce
} from "../cartSlice";
import type { CartState, CartStateDispatch } from "../cartSlice";
import type { CartCheckoutProps } from "../cart-types";
import type { CheckoutPrepare, PriceCalcResult } from "../piosolverStoreApi";

import { PaymentButtons, ReviewButtons, progressToSubmit } from "./shared";
import type { SubmitCheckoutFn } from "./shared";

type DropinError = {
  message: string;
};

type PaymentMethods = CartCheckoutProps["availablePaymentMethods"];

interface PaymentUiProps extends CheckoutPrepare {
  availablePaymentMethods: PaymentMethods;
  calculatedPrice: PriceCalcResult | undefined;
  setBraintreeDropin: (arg: Dropin | undefined) => void;
}

function applePayConfig(
  paymentMethods: PaymentMethods,
  amount: string
): Options["applePay"] {
  if (paymentMethods.applePay?.enabled !== true) return undefined;
  return {
    displayName: "PioSOLVER",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    paymentRequest: {
      total: {
        label: "PioSOLVER",
        amount
      }
    }
  };
}

function googlePayConfig(
  paymentMethods: PaymentMethods,
  totalPrice: string
): Options["googlePay"] {
  if (paymentMethods.googlePay?.environment == null) return undefined;
  const merchantId =
    paymentMethods.googlePay?.environment === "TEST"
      ? ""
      : paymentMethods.googlePay.merchantId ?? "";
  return {
    googlePayVersion: 2,
    merchantId,
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPrice,
      currencyCode: "USD"
    }
  };
}

function paypalConfig(
  paymentMethods: PaymentMethods,
  totalPrice: string
): Options["paypal"] {
  if (paymentMethods.paypal?.enabled !== true) return undefined;
  return {
    flow: "vault",
    amount: totalPrice,
    currency: "USD"
  };
}

function BraintreePaymentUi(props: PaymentUiProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [showSpinner, setShowSpinner] = React.useState(true);
  const { clientToken, setBraintreeDropin } = props;

  const totalPrice = props.calculatedPrice?.amount?.toFixed(2) ?? "0.00";
  const availablePaymentMethods = props.availablePaymentMethods;

  const applePay = applePayConfig(availablePaymentMethods, totalPrice);
  const googlePay = googlePayConfig(availablePaymentMethods, totalPrice);
  const paypal = paypalConfig(availablePaymentMethods, totalPrice);

  // If the payment methods change, we need to recreate the UI
  React.useEffect(() => {
    if (ref.current == null) return;
    // clear children of the container
    const node = ref.current;
    if (node == null) return;
    while (node.lastElementChild) {
      node.removeChild(node.lastElementChild);
    }
  }, [
    availablePaymentMethods.applePay?.enabled,
    availablePaymentMethods.googlePay?.enabled,
    availablePaymentMethods.paypal?.enabled
  ]);

  React.useEffect(() => {
    const createUi = async () => {
      const dropIn = await braintreeDropin.create({
        authorization: clientToken,
        container: "#braintree-container",
        // https://developer.paypal.com/braintree/docs/guides/premium-fraud-management-tools/client-side#drop-in
        dataCollector: true,
        threeDSecure: true,
        // https://developer.paypal.com/braintree/docs/guides/drop-in/customization
        card: {
          cardholderName: {
            required: true
          }
        },
        applePay,
        googlePay,
        paypal
      });
      setShowSpinner(false);
      setBraintreeDropin(dropIn);
    };
    createUi().catch((e: unknown) => {
      if (
        (e as DropinError)["message"] !==
        "options.selector or options.container must reference an empty DOM node."
      )
        setBraintreeDropin(undefined);
    });
  }, [applePay, clientToken, googlePay, paypal, setBraintreeDropin]);
  return (
    <div>
      <div className={showSpinner ? "d-inline" : "d-none"}>
        <Spinner />
      </div>
      <div
        className={showSpinner ? "d-none" : "visible"}
        ref={ref}
        id="braintree-container"></div>
    </div>
  );
}

function requestPaymentMethod(
  braintreeDropin: Dropin,
  state: CartState,
  dispatch: CartStateDispatch
) {
  if (braintreeDropin == null) {
    dispatch(setStatusFailed());
    return;
  }
  if (!braintreeDropin.isPaymentMethodRequestable()) return;
  const threeDSecure = {
    amount: cartToTotalPrice(state),
    email: state.contactEmail
  };
  const handlePayment = async () => {
    const paymentMethod = await braintreeDropin.requestPaymentMethod({
      threeDSecure
    });
    const nonce = paymentMethod.nonce;
    const deviceData = paymentMethod.deviceData;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dispatch(setNonceAndDeviceData({ nonce, deviceData }));
  };
  dispatch(setRequestingNonce({ isRequestingNonce: true }));
  handlePayment().catch((e: unknown) => {
    if ((e as DropinError)["message"] !== "No payment method is available.") {
      dispatch(setRequestingNonce({ isRequestingNonce: false }));
      dispatch(setStatusFailed);
    }
  });
}

function BraintreePaymentDetails(props: PaymentUiProps) {
  return (
    <div>
      <BraintreePaymentUi {...props} />
    </div>
  );
}

function BraintreePaymentHeader({
  isPayment,
  merchantId
}: {
  isPayment: boolean;
  merchantId: string;
}) {
  if (!isPayment) return null;

  // https://www.braintreepayments.com/badge
  return (
    <div className="d-flex justify-content-between">
      <strong>Payment Details</strong>
      <a
        href={`https://www.braintreegateway.com/merchants/${merchantId}/verified`}
        target="_blank"
        rel="noreferrer">
        <img
          alt="Braintree Payments Processor Badge"
          src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png"
          width="164px"
          height="44px"
        />
      </a>
    </div>
  );
}

interface PaymentProps extends CartCheckoutProps {
  submitCheckout: SubmitCheckoutFn;
}

function PaymentBraintreeGateway({
  availablePaymentMethods,
  checkout,
  state,
  submitCheckout
}: PaymentProps) {
  const dispatch = useCartStateDispatch();
  const [braintreeDropin, _setBraintreeDropin] = React.useState<
    Dropin | undefined
  >(undefined);
  const [proceedFailed, setProceedFailed] = React.useState<boolean | undefined>(
    undefined
  );
  const setBraintreeDropin = React.useCallback(
    (dropin: Dropin | undefined) => {
      if (dropin == null) return;
      dropin.on("paymentOptionSelected", () => setProceedFailed(undefined));
      dropin.on("changeActiveView", () => setProceedFailed(undefined));
      _setBraintreeDropin(dropin);
    },
    [_setBraintreeDropin]
  );
  const isPayment = state.status.step === 2;
  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (braintreeDropin == null) {
        setProceedFailed(false);
        dispatch(setStatusFailed);
        return;
      }
      if (!isPayment) {
        setProceedFailed(false);
        progressToSubmit(dispatch, state, checkout, submitCheckout);
        return;
      }
      if (!braintreeDropin.isPaymentMethodRequestable()) {
        setProceedFailed(true);
        return;
      }
      setProceedFailed(undefined);
      requestPaymentMethod(braintreeDropin, state, dispatch);
    },
    [braintreeDropin, checkout, dispatch, isPayment, state, submitCheckout]
  );
  if (checkout == null) return null;
  return (
    <form onSubmit={onSubmit}>
      <BraintreePaymentHeader
        isPayment={isPayment}
        merchantId={checkout.merchantId}
      />
      <BraintreePaymentDetails
        availablePaymentMethods={availablePaymentMethods}
        calculatedPrice={state.calculatedPrice}
        setBraintreeDropin={setBraintreeDropin}
        {...checkout}
      />
      {isPayment ? (
        <PaymentButtons isRequestingPaymentMethod={state.isRequestingNonce} />
      ) : (
        <ReviewButtons state={state} />
      )}
      {proceedFailed && (
        <div className="mt-3 text-danger">
          Please provide payment details to proceed to review.
        </div>
      )}
    </form>
  );
}

export default PaymentBraintreeGateway;
