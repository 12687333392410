import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { cartReducer } from "../features/cart";
import { piosolverStoreApi } from "../features/cart";

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    [piosolverStoreApi.reducerPath]: piosolverStoreApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(piosolverStoreApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
