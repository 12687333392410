import { STATUSES, setStatus, useCartStateDispatch } from "./cartSlice";
import type { CartStatus } from "./cartSlice";

const STATUS_MAP: { [key in CartStatus["label"]]?: CartStatus } = {};
STATUSES.forEach((status) => {
  STATUS_MAP[status.label] = status;
});

function useStatusNavigate() {
  const dispatch = useCartStateDispatch();
  return (nextLabel: CartStatus["label"]) => {
    const nextStatus = STATUS_MAP[nextLabel];
    dispatch(setStatus(nextStatus?.step ?? 0));
  };
}

function statusForLabel(label: CartStatus["label"]) {
  return STATUS_MAP[label];
}

export default useStatusNavigate;
export { statusForLabel };
