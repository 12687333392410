import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import { formControlCssClass } from "../../utils/errors";

import { ProductProps } from "./cart-types";
import { setContact, useCartStateDispatch } from "./cartSlice";
import { COUNTRY_LIST } from "./countries";
import { useCartCustomerContactMutation } from "./piosolverStoreApi";
import type { PriceCalcResult } from "./piosolverStoreApi";
import useStatusNavigate from "./useStatusNavigate";

const SUPPORT_EMAIL_ADDRESS = "support@piosolver.com";

type PriceCalculationErrorProps = { data?: PriceCalcResult };
function PriceCalculationError({ data }: PriceCalculationErrorProps) {
  if (data == null) return null;
  if (data.error == null) return null;
  if (data.error === "BadRequest" || data.error === "Unknown") {
    return (
      <div>
        There was a problem calculating the price for the items in your cart.
        Please to back and try again. If the problem persists, please contact us
        at <b>{SUPPORT_EMAIL_ADDRESS}</b>.
      </div>
    );
  }
  if (data.error === "ContactUs") {
    return (
      <div>
        Please reach out to us at <b>{SUPPORT_EMAIL_ADDRESS}</b> to discuss
        upgrading your license.
      </div>
    );
  }
  return <div>{data.message}</div>;
}

function EmailInformation() {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <Form.Group className="mb-3">
      <Form.Label>Contact email</Form.Label>
      <input
        className={formControlCssClass(errors.contactEmail != null)}
        id="contactEmail"
        type="email"
        placeholder="name@example.com"
        autoComplete="email"
        {...register("contactEmail", {
          required: true,
          // From https://www.emailregex.com
          pattern:
            // eslint-disable-next-line max-len
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        })}
      />
      {errors.contactEmail && (
        <span className="invalid-feedback">
          Please provide an email address.
        </span>
      )}
    </Form.Group>
  );
}

function NameInformation() {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <div className="mb-3">
      <div className="d-flex mb-3">
        <div className="me-1">
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <input
              className={formControlCssClass(errors.givenName != null)}
              id="givenName"
              placeholder="First Name"
              type="text"
              autoComplete="given-name"
              {...register("givenName", {
                required: false
              })}
            />
            {errors.givenName && (
              <span className="invalid-feedback">
                Please provide a first name.
              </span>
            )}
          </Form.Group>
        </div>
        <div className="flex-grow-1">
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <input
              className={formControlCssClass(errors.familyName != null)}
              id="familyName"
              placeholder="Last Name"
              type="text"
              autoComplete="family-name"
              {...register("familyName", {
                required: true
              })}
            />
            {errors.familyName && (
              <span className="invalid-feedback">
                Please provide a last name.
              </span>
            )}
          </Form.Group>
        </div>
      </div>
      <div>
        <input
          className={formControlCssClass(errors.companyName != null)}
          id="companyName"
          placeholder="Company"
          type="text"
          autoComplete="organization"
          {...register("companyName", {
            required: false
          })}
        />
      </div>
    </div>
  );
}

function AddressInformation() {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <div className="mb-3">
      <div className="mb-3">
        <Form.Group>
          <Form.Label>Billing Address</Form.Label>
          <input
            className={formControlCssClass(errors.streetAddress != null)}
            id="streetAddress"
            placeholder="Street Address"
            type="text"
            autoComplete="address-line1"
            {...register("streetAddress", {
              required: true
            })}
          />
          {errors.streetAddress && (
            <span className="invalid-feedback">
              Please provide a street address.
            </span>
          )}
        </Form.Group>
      </div>
      <div className="d-flex mb-3">
        <div className="me-1">
          <input
            className={formControlCssClass(errors.city != null)}
            id="city"
            placeholder="City / Locality"
            type="text"
            autoComplete="address-level2"
            {...register("city", {
              required: true
            })}
          />
          {errors.city && (
            <span className="invalid-feedback">
              Please provide a city or locality.
            </span>
          )}
        </div>
        <div className="me-1">
          <input
            className={formControlCssClass(errors.state != null)}
            id="state"
            placeholder="State / Province / Region"
            type="text"
            autoComplete="address-level1"
            {...register("state", {
              required: false
            })}
          />
          {errors.state && (
            <span className="invalid-feedback">
              Please provide a region (e.g., State or Province).
            </span>
          )}
        </div>
        <div>
          <input
            className={formControlCssClass(errors.postalCode != null)}
            id="postalCode"
            placeholder="Postal Code"
            type="text"
            autoComplete="postal-code"
            {...register("postalCode", {
              required: true
            })}
          />
          {errors.postalCode && (
            <span className="invalid-feedback">
              Please provide a postal code (ZIP code).
            </span>
          )}
        </div>
      </div>
      <div>
        <select
          className={formControlCssClass(errors.countryName != null)}
          id="countryName"
          placeholder="Country"
          autoComplete="country-name"
          {...register("countryName", {
            required: true,
            validate: (value) => value !== "Select a country"
          })}>
          <option key={"empty"} value={undefined}>
            Select a country
          </option>
          {COUNTRY_LIST.map((country) => {
            return (
              <option key={country.alpha2} value={country.name}>
                {country.name}
              </option>
            );
          })}
        </select>
        {errors.countryName && (
          <span className="invalid-feedback">Please select a country.</span>
        )}
      </div>
    </div>
  );
}

type ContactProps = Pick<ProductProps, "cartId" | "state">;

function Contact({ cartId, state }: ContactProps) {
  const [updateCustomer, { error: _error, isLoading: _isLoading }] =
    useCartCustomerContactMutation();
  const dispatch = useCartStateDispatch();
  const statusNavigate = useStatusNavigate();
  const formMethods = useForm({
    defaultValues: {
      contactEmail: state.contactEmail,
      givenName: state.contactName?.givenName,
      familyName: state.contactName?.familyName,
      companyName: state.contactName?.companyName,
      streetAddress: state.billingAddress?.streetAddress,
      city: state.billingAddress?.city,
      state: state.billingAddress?.state,
      postalCode: state.billingAddress?.postalCode,
      countryName: state.billingAddress?.countryName
    }
  });
  const calculatedPrice = state.calculatedPrice;
  if (calculatedPrice?.error != null) {
    return (
      <>
        <PriceCalculationError data={state.calculatedPrice} />
        <div className="d-flex">
          <Button variant="link" onClick={() => statusNavigate("initial")}>
            Back
          </Button>
        </div>
      </>
    );
  }
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return (
    <FormProvider {...formMethods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit(async (data) => {
          const contact = {
            contactEmail: data.contactEmail,
            contactName: {
              givenName: data.givenName,
              familyName: data.familyName!,
              companyName: data.companyName
            },
            billingAddress: {
              streetAddress: data.streetAddress!,
              city: data.city!,
              state: data.state,
              postalCode: data.postalCode!,
              countryName: data.countryName!
            }
          };
          try {
            await updateCustomer({ cartId, ...contact });
          } finally {
            // it is not a big problem if the update fails, we can still continue
            dispatch(setContact(contact));
          }
        })}>
        <EmailInformation />
        <div className="mb-1">&nbsp;</div>
        <NameInformation />
        <div className="mb-1">&nbsp;</div>
        <AddressInformation />
        <div className="d-flex flex-row-reverse justify-content-between">
          <Button variant="primary" type="submit">
            Continue to Payment
          </Button>
          <Button variant="link" onClick={() => statusNavigate("initial")}>
            Back
          </Button>
        </div>
      </form>
    </FormProvider>
  );
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export default Contact;
