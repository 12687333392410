import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

// From: https://redux-toolkit.js.org/rtk-query/usage-with-typescript

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown
): error is { data: { message: string } } {
  if (!isFetchBaseQueryError(error)) return false;
  const data = error.data;
  return (
    typeof data === "object" &&
    data != null &&
    "message" in data &&
    typeof data.message === "string"
  );
}

export function formControlCssClass(hasError: boolean) {
  return hasError ? "form-control is-invalid" : "form-control";
}
