/* eslint-disable spellcheck/spell-checker */
// const USE_V3 = process.env.REACT_APP_ENV === "dev";
const USE_V3 = true;

function isActivationProduct(productName: string) {
  return productName.startsWith("Activation");
}

function isExtensionProduct(productName: string) {
  return productName.startsWith("Extension");
}

function isV2Product(productName: string) {
  return productName.endsWith("_v2");
}

const ProductsMain = USE_V3
  ? [
      {
        title: "PioSOLVER 3.0 Pro",
        titleShort: "Pro_v3",
        price: 249.0,
        image: "/img/proev_1024x1024.webp"
      },
      {
        title: "PioSOLVER 3.0 Edge",
        titleShort: "Edge_v3",
        price: 549.0,
        image: "/img/edgeev_1024x1024.webp"
      },
      {
        title: "Support Extension",
        titleShort: "Extension_v3",
        price: -1,
        image: "/img/edgeev_1024x1024.webp"
      },
      {
        title: "Additional Activation",
        titleShort: "Activation_v3",
        price: -1,
        image: "/img/edgeev_1024x1024.webp"
      }
    ]
  : [
      {
        title: "PioSOLVER 2.0 Basic",
        titleShort: "Basic_v2",
        price: 249.0,
        image: "/img/basicev_1024x1024.webp"
      },
      {
        title: "PioSOLVER 2.0 Pro",
        titleShort: "Pro_v2",
        price: 475.0,
        image: "/img/proev_1024x1024.webp"
      },
      {
        title: "PioSOLVER 2.0 Edge",
        titleShort: "Edge_v2",
        price: 1099.0,
        image: "/img/edgeev_1024x1024.webp"
      }
    ];

export { ProductsMain, isActivationProduct, isExtensionProduct, isV2Product };
