import React from "react";
import Form from "react-bootstrap/Form";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import { formControlCssClass } from "../../../utils/errors";

import {
  setNonceAndDeviceData,
  useCartStateDispatch,
  setRequestingNonce
} from "../cartSlice";
import type { CartStateDispatch } from "../cartSlice";
import type { CartCheckoutProps } from "../cart-types";

import { PaymentButtons, ReviewButtons, progressToSubmit } from "./shared";
import type { SubmitCheckoutFn } from "./shared";

function requestPaymentMethod(promoCode: string, dispatch: CartStateDispatch) {
  const handlePayment = () => {
    const deviceData = "";
    dispatch(setNonceAndDeviceData({ nonce: promoCode, deviceData }));
  };
  dispatch(setRequestingNonce({ isRequestingNonce: true }));
  handlePayment();
}

type DummyPaymentComponentProps = {
  isPayment: boolean;
  amount: number | undefined;
};

function PaymentHeader({ amount, isPayment }: DummyPaymentComponentProps) {
  if (!isPayment) return null;

  if (amount != null && amount < 1) {
    return (
      <div className="d-flex flex-column justify-content-between">
        <strong>Free upgrade</strong>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column justify-content-between">
      <strong>Purchase Code</strong>
    </div>
  );
}

function PromoCodeForm({ amount, isPayment }: DummyPaymentComponentProps) {
  const {
    getValues,
    register,
    formState: { errors }
  } = useFormContext();

  if (amount != null && amount < 1) {
    return <div className="d-flex flex-column justify-content-between"></div>;
  }

  if (!isPayment)
    return (
      <Form.Group className="mb-3 pe-5">
        <Form.Label>Ordering using purchase code</Form.Label>
        <div>{getValues("promoCode")}</div>
      </Form.Group>
    );

  return (
    <Form.Group className="mb-3 pe-5">
      <Form.Label>Enter your purchase code</Form.Label>
      <input
        className={formControlCssClass(errors.contactEmail != null)}
        id="promoCode"
        type="text"
        placeholder="purchase code"
        autoComplete="off"
        {...register("promoCode", {
          required: true
        })}
      />
      {errors.promoCode && (
        <span className="invalid-feedback">
          Please provide a purchase code.
        </span>
      )}
    </Form.Group>
  );
}

interface PaymentProps extends CartCheckoutProps {
  submitCheckout: SubmitCheckoutFn;
}

function PaymentPromoCodeGateway({
  checkout,
  state,
  submitCheckout
}: PaymentProps) {
  const dispatch = useCartStateDispatch();
  const formMethods = useForm({
    defaultValues: {
      promoCode: state.nonce ?? ""
    }
  });
  if (checkout == null) return null;
  const isPayment = state.status.step === 2;
  const amount = state.calculatedPrice?.amount;
  return (
    <FormProvider {...formMethods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formMethods.handleSubmit((data) => {
          isPayment
            ? requestPaymentMethod(data.promoCode, dispatch)
            : progressToSubmit(dispatch, state, checkout, submitCheckout);
        })}>
        <PaymentHeader amount={amount} isPayment={isPayment} />
        <PromoCodeForm amount={amount} isPayment={isPayment} />
        {isPayment ? (
          <PaymentButtons isRequestingPaymentMethod={state.isRequestingNonce} />
        ) : (
          <ReviewButtons state={state} />
        )}
      </form>
    </FormProvider>
  );
}

export default PaymentPromoCodeGateway;
