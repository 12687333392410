import React from "react";

function BackendDown() {
  return (
    <div>
      <h3>Technical Difficulties</h3>
      <div className="mb-4">
        Sorry, we are unable to process payments at the moment. Please try again
        in a few minutes.
      </div>
    </div>
  );
}

export default BackendDown;
