import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {
  setNonceAndDeviceData,
  useCartStateDispatch,
  setRequestingNonce
} from "../cartSlice";
import type { CartStateDispatch } from "../cartSlice";
import type { CartCheckoutProps } from "../cart-types";

import { PaymentButtons, ReviewButtons, progressToSubmit } from "./shared";
import type { SubmitCheckoutFn } from "./shared";

function requestPaymentMethod(nonce: string, dispatch: CartStateDispatch) {
  const handlePayment = () => {
    const deviceData = "";
    dispatch(setNonceAndDeviceData({ nonce, deviceData }));
  };
  dispatch(setRequestingNonce({ isRequestingNonce: true }));
  handlePayment();
}

type DummyPaymentComponentProps = {
  isPayment: boolean;
};

function DummyPaymentHeader({ isPayment }: DummyPaymentComponentProps) {
  if (!isPayment) return null;

  return (
    <div className="d-flex flex-column justify-content-between">
      <strong>Payment Details</strong>
    </div>
  );
}

interface NonceFormProps extends DummyPaymentComponentProps {
  nonce: string;
  setNonce: (nonce: string) => void;
}

function NonceForm({ isPayment, nonce, setNonce }: NonceFormProps) {
  if (!isPayment) return null;

  return (
    <Form.Group className="mb-3 pe-5">
      <Form.Label>
        Nonce from{" "}
        <a href="https://developer.paypal.com/braintree/docs/reference/general/testing#nonces-representing-cards">
          Testing Nonces
        </a>
      </Form.Label>
      <InputGroup>
        <input
          id="nonce"
          type="text"
          className="form-control"
          value={nonce}
          onChange={(e) => setNonce(e.target.value)}
        />
      </InputGroup>
    </Form.Group>
  );
}

interface PaymentProps extends CartCheckoutProps {
  submitCheckout: SubmitCheckoutFn;
}

function PaymentBraintreeTestGateway({
  checkout,
  state,
  submitCheckout
}: PaymentProps) {
  const dispatch = useCartStateDispatch();
  const [nonce, setNonce] = React.useState("fake-valid-nonce");
  if (checkout == null) return null;
  const isPayment = state.status.step === 2;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        isPayment
          ? requestPaymentMethod(nonce, dispatch)
          : progressToSubmit(dispatch, state, checkout, submitCheckout);
      }}>
      <DummyPaymentHeader isPayment={isPayment} />
      <NonceForm isPayment={isPayment} nonce={nonce} setNonce={setNonce} />
      {isPayment ? (
        <PaymentButtons isRequestingPaymentMethod={state.isRequestingNonce} />
      ) : (
        <ReviewButtons state={state} />
      )}
    </form>
  );
}

export default PaymentBraintreeTestGateway;
