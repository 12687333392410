import React from "react";
import Button from "react-bootstrap/Button";

import { Spinner } from "../../../utils/spinner";

import { setStatus, setSubmitResult } from "../cartSlice";
import type { CartStateDispatch } from "../cartSlice";
import type { CartStateProps } from "../cart-types";
import { useCheckoutSubmitMutation } from "../piosolverStoreApi";
import type { CheckoutPrepare } from "../piosolverStoreApi";
import useStatusNavigate from "../useStatusNavigate";

type SubmitCheckoutFn = ReturnType<typeof useCheckoutSubmitMutation>[0];

type PaymentButtonsProps = { isRequestingPaymentMethod: boolean };
function PaymentButtons({ isRequestingPaymentMethod }: PaymentButtonsProps) {
  const statusNavigate = useStatusNavigate();
  const spinnerClass = isRequestingPaymentMethod ? "d-inline" : "d-none";
  return (
    <div className="d-flex flex-row-reverse justify-content-between">
      <Button
        disabled={isRequestingPaymentMethod}
        variant="primary"
        type="submit">
        <span className={spinnerClass}>
          <Spinner size={24} />
        </span>
        Continue to Review
      </Button>
      <Button
        disabled={isRequestingPaymentMethod}
        variant="link"
        onClick={() => statusNavigate("contact")}>
        Back
      </Button>
    </div>
  );
}

function ReviewButtons(_props: CartStateProps) {
  const statusNavigate = useStatusNavigate();
  return (
    <div className="d-flex flex-row-reverse justify-content-between">
      <Button variant="primary" type="submit">
        Place order
      </Button>
      <Button variant="link" onClick={() => statusNavigate("payment")}>
        Back
      </Button>
    </div>
  );
}

function progressToSubmit(
  dispatch: CartStateDispatch,
  state: CartStateProps["state"],
  checkout: CheckoutPrepare,
  submitCheckout: SubmitCheckoutFn
) {
  const {
    orderType,
    version,
    numberOfLicenses,
    contactEmail,
    contactName,
    billingAddress,
    nonce,
    deviceData
  } = state;
  const params = {
    cartId: checkout.cartId,
    orderType,
    version,
    numberOfLicenses,
    contactEmail,
    contactName,
    billingAddress,
    nonce,
    deviceData
  };
  dispatch(setStatus(4));
  submitCheckout(params)
    .then((result) => {
      dispatch(setSubmitResult({ submitResult: result }));
    })
    .catch((e) => {
      dispatch(setStatus(6));
    });
}

export { PaymentButtons, ReviewButtons, progressToSubmit };
export type { SubmitCheckoutFn };
