import React from "react";

import {
  setNonceAndDeviceData,
  useCartStateDispatch,
  setRequestingNonce
} from "../cartSlice";
import type { CartStateDispatch } from "../cartSlice";
import type { CartCheckoutProps } from "../cart-types";

import { PaymentButtons, ReviewButtons, progressToSubmit } from "./shared";
import type { SubmitCheckoutFn } from "./shared";

function requestPaymentMethod(dispatch: CartStateDispatch) {
  const handlePayment = () => {
    const nonce = "dummy";
    const deviceData = "dummy";
    dispatch(setNonceAndDeviceData({ nonce, deviceData }));
  };
  dispatch(setRequestingNonce({ isRequestingNonce: true }));
  handlePayment();
}

function DummyPaymentHeader({ isPayment }: { isPayment: boolean }) {
  if (!isPayment) return null;

  return (
    <div className="d-flex justify-content-between">
      <strong>Payment Details</strong>
    </div>
  );
}

interface PaymentProps extends CartCheckoutProps {
  submitCheckout: SubmitCheckoutFn;
}

function PaymentDummyGateway({
  checkout,
  state,
  submitCheckout
}: PaymentProps) {
  const dispatch = useCartStateDispatch();
  if (checkout == null) return null;
  const isPayment = state.status.step === 2;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        isPayment
          ? requestPaymentMethod(dispatch)
          : progressToSubmit(dispatch, state, checkout, submitCheckout);
      }}>
      <DummyPaymentHeader isPayment={isPayment} />
      {isPayment ? (
        <PaymentButtons isRequestingPaymentMethod={state.isRequestingNonce} />
      ) : (
        <ReviewButtons state={state} />
      )}
    </form>
  );
}

export default PaymentDummyGateway;
